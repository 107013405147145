<template>
  <div class="RoofingPage">
    <div class="gallery">
      <lightgallery
      :settings="gallerySettings"
      @onInit="onInit"
      @onBeforeSlide="onBeforeSlide"
    >
    <a v-for="(image, index) in images" :key="index" :href="image">
        <img class="thumbnail" :alt="'img' + index" :src="image" />
      </a>
    </lightgallery>
    </div>
  
    <PageFooter />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import PageFooter from '@/components/PageFooter.vue';
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/scss/lightgallery.scss';

export default defineComponent({
  components: {
    Lightgallery,
    PageFooter
  },
  setup() {
    const plugins = ref([lgThumbnail, lgZoom]);

    const gallerySettings = ref({
      speed: 500,
      plugins: plugins.value,
      thumbnail: false, // Disable thumbnails
    });

    const onInit = () => {
      console.log('lightGallery has been initialized');
    };

    const onBeforeSlide = (event) => {
      console.log('before slide', event.detail.index);
    };

    const importAll = (r) => r.keys().map(r);
    const images = importAll(require.context('../../assets/NewRoof/resized', false, /\.(png|jpe?g|svg)$/));


    return {
      plugins,
      gallerySettings,
      onInit,
      onBeforeSlide,
      images
    };
  },
});
</script>

<style scoped>
.RoofingPage {
  background-color: #212121;
  display: flex;
  flex-direction: column;
  margin-top: 88px;
}

.thumbnail {
  width: 20%; /* Set the desired thumbnail width */
  margin: 15px;
  cursor: pointer;
  transition: transform 0.3s;
}

.thumbnail:hover {
  transform: scale(1.1); /* Optional: Add a hover effect */
}

/* Custom styles for lightgallery */
.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.9) !important; /* Darker background */
}

.lg-toolbar {
  background-color: rgba(0, 0, 0, 0.7) !important; /* Semi-transparent toolbar */
}

.lg-sub-html {
  color: #fff !important; /* White text for captions */
}

.lg-thumb-outer {
  background-color: rgba(0, 0, 0, 0.7) !important; /* Semi-transparent thumbnail background */
}

/* Responsive styles for mobile */
@media (max-width: 900px) {
  .gallery {
    margin-top: 20%;
}
  .thumbnail {
    width: 40%; /* Smaller thumbnail width for mobile */
  }

  .lg-toolbar {
    background-color: rgba(0, 0, 0, 0.9) !important; /* Darker toolbar for better visibility on mobile */
  }

  .lg-sub-html {
    font-size: 14px !important; /* Smaller font size for captions on mobile */
  }
}
</style>