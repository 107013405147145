<template>
  <div>
    Hi
  </div>
  </template>
  
  <script>
  export default {
    name: 'GutterPage',
  };
  </script>