<template>
<div>
  Hi
</div>
</template>

<script>
export default {
  name: 'SidingPage',
};
</script>